<template>
  <v-container v-if="!loading" id="stocks" fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-clipboard-text"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $t("stock_types") }}</b>
        </h1></template
      >
      <v-btn
        absolute
        fab
        top
        right
        @click="open_dialog()"
        color="primary"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("stock_type") }}</th>
            <th class="text-center">
              {{ $t("description") }}
            </th>
            <th class="text-center">
              {{ $t("edit") }}
            </th>
            <th class="text-center">
              {{ $t("remove") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(s, i) in stocks" :key="i">
            <td>{{ s.Name }}</td>
            <td class="text-center">
              {{ s.Description }}
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_dialog(s)"
                color="#7733FF"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(s)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>

    <v-dialog v-model="dialog">
      <div v-if="dialog">
        <AddStockTypeModal
          :company_id="company_id"
          :stock="stock"
          @close="reload_stocks"
        ></AddStockTypeModal>
      </div>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name"
        @close="delete_dialog = false"
        @delete="delete_stock"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import AddStockTypeModal from "@/views/pages/stock/form/AddStockTypeModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";

export default {
  name: "StockTypesTable",
  components: {
    AddStockTypeModal,
    DeleteConfirmationModal,
  },
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    stock: null,
    delete_obj: null,
    stocks: [],
  }),
  async created() {
    this.loading = true;
    await this.get_stocks();
  },
  methods: {
    async get_stocks() {
      await this.apiService
        .getRequest("stock")
        .then((resp) => {
          this.stocks = JSON.parse(resp.message);
          this.loading = false;
        })
        .catch((error) => {});
    },
    open_dialog: function (stock) {
      this.dialog = true;
      this.stock = stock;
    },
    reload_stocks(stock) {
      if (stock) {
        var index = this.stocks.map((x) => x.Id).indexOf(stock.Id);
        if (index == -1) {
          this.stocks.push(stock);
        } else {
          this.stocks[index] = stock;
        }
      }
      this.reset_dialog();
    },
    reset_dialog() {
      this.dialog = false;
      this.stock = null;
    },
    open_delete_dialog: function (stock) {
      this.delete_dialog = true;
      this.delete_obj = stock;
    },
    async delete_stock(stock) {
      var index = this.stocks.indexOf(stock);
      this.stocks.splice(index, 1);
      await this.apiService.deleteRequest("stock", stock);
      this.delete_dialog = false;
    },
  },
  props: {
    company_id: String,
    files: Array,
    is_admin: Boolean,
  },
};
</script>
