<template>
  <v-container id="add-stocktype-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title>
        <h4 class="dxa_modal_title h4">{{ $t("new_stock_type") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form" class="mt-5">
          <v-text-field
            v-model="type.Name"
            :label="$t('stock_type')"
            class="pt-0"
          />
          <v-text-field
            v-model="type.Description"
            :label="$t('description')"
            class="pt-0"
          />
          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close')"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "ManageStockTypeModal",
  props: {
    company_id: String,
    stock: Object,
  },

  data: () => {
    return {
      apiService: null,
      loading: false,
      type: {},
    };
  },
  created() {
    this.apiService = new ApiService();
    if (this.stock) {
      this.type = JSON.parse(JSON.stringify(this.stock));
    }
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (!this.stock) {
        await this.apiService
          .postRequest("stock", this.type)
          .then((resp) => {
            this.$emit("close", JSON.parse(resp.message));
          })
          .catch((error) => {});
        this.loading = false;
      } else {
        this.$emit("close", this.type);
        this.loading = false;
        await this.apiService.putRequest("stock", this.type);
      }
    },
  },
};
</script>
